import React, {useState} from 'react';
import {Button, ButtonProps, Col, Form, Row} from "react-bootstrap";
import {Formik, FormikFormProps, FormikHelpers, useField, useFormikContext} from 'formik';
import {LoadingSpinner} from "../Loading";
import {FormInputFieldProps, FormTextareaFieldProps} from "react-bootstrap-formik";

const MyForm = () => {
}

interface TextControlProps {
    controlRef?: React.RefObject<any>,
}

type MyFormInputProps =  FormInputFieldProps & FormTextareaFieldProps & TextControlProps
type MyFormButtonProps = ButtonProps & {block?: boolean}


export const TextControl = ({controlRef, label, ...props}: MyFormInputProps) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)
    const [field, meta] = useField(props);
    //console.log("TextControl", field, meta)
    const controlId = props.id || props.name
    return (
        <Form.Group className="mb-3" ref={controlRef} controlId={controlId}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                as="input"
                className={meta.touched && meta.error ? "is-invalid" : ''}
                {...field} {...props}
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
            {/*<ErrorMessage name={props.id || props.name} />*/}
        </Form.Group>
    )
};

export const DateControl = ({label, ...props}: MyFormInputProps) => {
    return (
        <TextControl label={label} type="date" {...props}/>
    )
};

export const TextareaControl = ({label, ...props}: MyFormInputProps) => {
    return (
        <TextControl label={label} as="textarea" {...props}/>
    )
};


export const PasswordControl = ({label, ...props}: MyFormInputProps) => {
    return (
        <TextControl label={label} type="password" {...props}/>
    )
};

export const EmailControl = ({label, ...props}: MyFormInputProps) => {
    return (
        <TextControl label={label} type="email" {...props}/>
    )
};

export const HiddenControl = (props: MyFormInputProps) => {
    // @ts-ignore
    const [field, meta] = useField(props);
    return (
        <>
            <Form.Control
                type="hidden"
                as="input"
                className={meta.touched && meta.error ? "is-invalid" : ''}
                {...field} {...props}
            />
        </>
    )
};

export const CheckboxControl = ({label, ...props}: any) => {
    // React treats radios and checkbox inputs differently other input types, select, and textarea.
    // Formik does this too! When you specify `type` to useField(), it will
    // return the correct bag of props for you -- a `checked` prop will be included
    // in `field` alongside `name`, `value`, `onChange`, and `onBlur`

    const [field, meta] = useField({...props, type: 'checkbox'});
    //console.log("CheckboxControl", props, field, meta)
    const controlId = props.id || props.name;
    return (
        <>
            <Form.Group className="mb-3 form-check" controlId={controlId}>
                <Form.Check type="checkbox">
                    <Form.Check.Input
                        className={meta.touched && meta.error ? "is-invalid" : ""}
                        {...field}
                        {...props}
                    />
                    <Form.Check.Label>
                        {label}
                    </Form.Check.Label>
                    {meta.touched && meta.error ? (
                        <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                    ) : null}
                </Form.Check>
            </Form.Group>
        </>
    );
};

export const SelectControl = ({label, options, empty, ...props}: any) => {
    const [field, meta, helpers] = useField(props);
    const emptyLabel = empty && typeof empty === "string" ? empty : "-- Select --"
    //console.log("SelectInput", field, meta, props)
    const controlId = props.id || props.name;
    return (
        <Form.Group className="mb-3" controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <Form.Select
                className={meta.touched && meta.error ? "is-invalid" : null}
                {...field} {...props}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    helpers.setValue(e.target.value)
                }}
            >
                {empty && <option value="">{emptyLabel}</option>}
                {Array.isArray(options) && options.map((option, idx) => (
                    <option key={idx} value={option.value}>{option.label}</option>
                ))}
            </Form.Select>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </Form.Group>
    )
};

export const SelectOtherControlRow = ({label, options, empty, otherVal, otherProps, onChange, ...props}: any) => {
    const [selectField, selectMeta] = useField(props);
    const [isOther, setIsOther] = useState(otherVal === selectMeta.value)
    //const {onChange, ...customField} = selectField;
    const emptyLabel = empty && typeof empty === "string" ? empty : "-- Select --"

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("SelectOtherControlRow:handleChange", e, onChange)
        let _selectedId = parseInt(e.target.value);
        let _otherId = parseInt(otherVal);
        setIsOther(_selectedId === _otherId)

        if (onChange) {
            onChange(e); // formik onChange handler
        }
    }

    const controlId = props.id || props.name
    return (
        <Row>
            <Col>
                <Form.Group className="mb-3" controlId={controlId}>
                    <Form.Label>{label}</Form.Label>
                    <Form.Control
                        //custom
                        as="select"
                        className={selectMeta.touched && selectMeta.error ? "is-invalid" : "form-select"}
                        {...selectField}
                        onChange={handleChange}
                    >
                        {empty && <option value="">{emptyLabel}</option>}
                        {Array.isArray(options) && options.map((option, idx) => (
                            <option key={idx} value={option.value}>{option.label}</option>
                        ))}
                    </Form.Control>
                    {selectMeta.touched && selectMeta.error ? (
                        <div className="invalid-feedback">{selectMeta.error}</div>
                    ) : null}
                </Form.Group>
            </Col>
            <Col>
                {isOther && <TextControl {...otherProps} />}
            </Col>
        </Row>
    )
}

export const CancelButton = ({children, block, ...props}: MyFormButtonProps) => {
    let button = <Button
        variant="outline-secondary"
        {...props}
    >Cancel</Button>

    if (block) {
        return <div className="d-grid gap-2">{button}</div>
    }
    return button
}

export const SubmitButton = ({children, block, ...props}: MyFormButtonProps) => {
    const formik = useFormikContext()
    let button;
    if (!formik.isValid) {
        button = (
            <Button type="submit"
                    variant={"secondary"}
                    data-submitting={false}
                    disabled={true}
                    title={"Please fill all required fields"}
                    {...props}>
                {children || 'Submit'}
            </Button>
        )
    } else if (formik.isSubmitting) {
        button = (
            <Button type="button"
                    variant="primary"
                    disabled={true}
                    data-submitting={true}
                    {...props}>
                <LoadingSpinner size={20}/>
            </Button>
        )
    } else {
        button = (
            <Button type="submit"
                    variant={"primary"}
                    data-submitting={false}
                    {...props}>
                {children || 'Submit'}
            </Button>
        )
    }

    if (block) {
        return <div className="d-grid gap-2">{button}</div>
    }

    return button
}

export interface SubmitButtonFormProps extends MyFormButtonProps {
    onFormSubmit: (values: any, formik: FormikHelpers<any>) => any,
    formProps?: FormikFormProps,
}

export const SubmitButtonForm = ({onFormSubmit, children, formProps, ...props}: SubmitButtonFormProps) => {
    return (
        <Formik
            initialValues={{}}
            onSubmit={onFormSubmit}
            {...formProps}>
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="d-inline-block">
                    <SubmitButton {...props}>
                        {children}
                    </SubmitButton>
                </Form>
            )}
        </Formik>
    )
}

export default MyForm;
