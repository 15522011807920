import React from 'react';
import {Accordion, Card, Col, Container, Row} from "react-bootstrap";

function ServicesSection() {
    return (
        <Container>
            <h2 className="text-center">Services</h2>
            <Row>
                <Col md={12}>
                    <div className="px-3 py-3 shadow-0">
                        <Card className="text-left border-0 no-bg">
                            <Card.Body>
                                <Card.Text className="text-center">
                                    Our facility provides advanced analytical methods designed to meet the needs
                                    of a wide range of researchers - from those studying fundamental mechanisms
                                    of cellular metabolism to <i>in situ</i> clinical researchers. To enable all
                                    studies,
                                    the core provides web-based experiment management environment
                                    integrated with scalable cloud-based tools for data processing,
                                    analysis, and sharing. The core employs tools that minimizes the time it
                                    takes to go from experiment to biological insight.
                                </Card.Text>
                                <Card.Text className='text-center'><i>
                                    For studies of more than 500 samples, please contact us for special pricing.</i>
                                </Card.Text>
                                 <Card.Text className='text-center'><i>
                                    Pricing shown below is subject to changes. We will provide you with a quote after a consultation
                                    </i>
                                </Card.Text>
                                {/*<Card.Text className="text-center">*/}
                                {/*    Prices for the various Core services will be competitive with core*/}
                                {/*    facilities nationally, and are in the process of being determined.*/}
                                {/*</Card.Text>*/}
                            </Card.Body>
                        </Card>

                        <Accordion className="p-5">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    High-throughput metabolome profiling
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        Untargeted metabolomics is the method of choice for obtaining a
                                        global
                                        view of metabolic phenotypes
                                        and identifying dysregulated metabolic pathways. For large sample sizes,
                                        high throughput is a prerequisite for timely analysis.
                                         To enable discovery
                                        and
                                        hypothesis generation
                                        for a wide range of small molecules extracted from diverse
                                        biological
                                        materials,
                                        we offer high-throughput flow-injection quadrupole time-of-flight
                                        mass spectrometry
                                        using two Agilent 6550 iFunnel Q-TOF FIA-MS platforms.
                                    </div>
                                    <div>
                                        Requiring only minute quantities of biological materials
                                         extracted using simple protocols,
                                        FIA-QTOF based untargeted
                                        metabolomics is very well suited for large scale clinical and
                                        epidemiological studies, while simultaneously enabling rapid data
                                        delivery for
                                        laboratory studies with complex design matrices and large numbers of
                                        biological replicates. Our FIA-qTOF method delivers putative annotations
                                        for 100's of metabolites, which can subsequently be validated using targeted LC/MS/MS approaches.
                                        For highest scientific standards, we offer downstream
                                        validation packages to ensure an uninterrupted chain of experiments.

                                    </div>
                                    <div className="card-text">
                                        <ul className="mt-3">
                                            <li><a href="https://pubmed.ncbi.nlm.nih.gov/32694660/"
                                                   target="_blank"
                                                   rel="noreferrer">Glucose-dependent
                                                partitioning of arginine to the urea cycle protects β-cells
                                                from
                                                inflammation</a> <i>Nature Metabolism (2020)</i></li>
                                            <li><a href="https://pubmed.ncbi.nlm.nih.gov/28783731/"
                                                   target="_blank"
                                                   rel="noreferrer">Metabolic
                                                control of T H 17 and induced T reg cell balance by an
                                                epigenetic mechanism</a> <i>Nature (2017)</i></li>
                                            <li><a href="https://pubmed.ncbi.nlm.nih.gov/27745970/"
                                                   target="_blank"
                                                   rel="noreferrer">L-Arginine
                                                Modulates T Cell Metabolism and Enhances Survival and
                                                Anti-tumor
                                                Activity</a> <i>Cell (2016)</i></li>
                                        </ul>
                                    </div>

                                    <div>
                                        <table>
                                            <caption className="caption-side:top">Per-sample pricing</caption>
                                            <thead>
                                            <tr>
                                                <th>Internal</th>
                                                <th>External</th>
                                                <th>Corporate</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>$85.00</td>
                                                <td>$114.75</td>
                                                <td>$165.75</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    Metabolite analysis using LC/MS
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        Metabolite analysis on LC/MS(/MS) uses liquid chromatography to
                                        separate compounds according to
                                        their physicochemical properties. Our LC/MS methods are well suited for
                                        untargeted analysis of up to 100 samples. Our targeted LC/MS/MS methods utilize fragmentation (MS2)
                                       combined with appropriate internal or external chemical standards allow unambiguous
                                       compound identification and quantification.
                                    </div>
                                    <div>
                                        <table>
                                            <caption className="caption-side:top">Per-sample pricing</caption>
                                            <thead>
                                            <tr>
                                                <th>Internal</th>
                                                <th>External</th>
                                                <th>Corporate</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>$125.00</td>
                                                <td>$168.75</td>
                                                <td>$243.75</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Multi-omics network integration
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        High-throughput steady-state metabolomic profiling yields
                                        information
                                        about changes of hundreds of metabolites. The insights gained from
                                        these data
                                        can be significantly enhanced by mapping metabolite abundances to
                                        the
                                        underlying cellular reaction
                                        network, and using graph-based algorithms to identify co-regulation
                                        with
                                        other types of omics data, such as transcriptional profiling.
                                        Analyzing metabolites in the context of the enzymatic pathways
                                        underlying their interconversion,
                                        combined with transcriptional or proteomic data representing the
                                        genes
                                        and proteins encoding
                                        the enzymes can reveal novel insights about underlying biological
                                        processes.
                                    </div>
                                    <div className="card-text">
                                        <ul className="mt-3">
                                            <li><a href="https://pubmed.ncbi.nlm.nih.gov/25786174/"
                                                   target="_blank"
                                                   rel="noreferrer">Network
                                                integration of parallel metabolic and transcriptional data
                                                reveals metabolic modules that regulate macrophage
                                                polarization
                                            </a> <i>Immunity (2015)</i></li>
                                            <li><a href="https://pubmed.ncbi.nlm.nih.gov/27098040/"
                                                   target="_blank"
                                                   rel="noreferrer">GAM: a
                                                web-service for integrated transcriptional and metabolic
                                                network
                                                analysis
                                            </a> <i>Nucleic Acids Research (2015)</i></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <table>
                                            <caption className="caption-side:top">Per-analysis pricing starting at</caption>
                                            <thead>
                                            <tr>
                                                <th>Internal</th>
                                                <th>External</th>
                                                <th>Corporate</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>$2000.00</td>
                                                <td>$2700.00</td>
                                                <td>$3900.00</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/*<Accordion.Item eventKey="3">*/}
                            {/*    <Accordion.Header>*/}
                            {/*        Isotopic fate-mapping (targeted and untargeted)*/}
                            {/*    </Accordion.Header>*/}
                            {/*    <Accordion.Body>*/}
                            {/*        <div>*/}
                            {/*            When measuring metabolite abundances is not enough, using stable*/}
                            {/*            isotopically labeled*/}
                            {/*            compounds to trace the metabolic fate of nutrients and identify*/}
                            {/*            active pathway flows can reveal*/}
                            {/*            differentially regulated*/}
                            {/*            metabolic pathways and help identify the mechanisms underlying*/}
                            {/*            metabolic*/}
                            {/*            responses*/}
                            {/*            to perturbations.*/}
                            {/*        </div>*/}
                            {/*        <div>*/}
                            {/*            <Badge pill bg="info">Coming 2022</Badge>*/}
                            {/*        </div>*/}
                            {/*    </Accordion.Body>*/}
                            {/*</Accordion.Item>*/}

                            {/*<Accordion.Item eventKey="4">*/}
                            {/*    <Accordion.Header>*/}
                            {/*        Custom Method Development*/}
                            {/*    </Accordion.Header>*/}
                            {/*    <Accordion.Body>*/}
                            {/*        <div>*/}
                            {/*            The core is currently in the process of recruiting and hiring the*/}
                            {/*            scientific team to run the facility. Once that team is in place, the*/}
                            {/*            Core intends to work closely with Dana-Farber researchers in special cases*/}
                            {/*            to enable development of custom methods for sample preparation or*/}
                            {/*            analysis. Stay tuned for updates on this future capability.*/}
                            {/*        </div>*/}
                            {/*    </Accordion.Body>*/}
                            {/*</Accordion.Item>*/}
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    Custom
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        For services outside of these categories, please <a href="mailto:dfcimetabolomics@dfci.harvard.edu">contact us</a>.
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ServicesSection;
