import React from 'react';
import logo from "../../../assets/img/logo_gmet_icon_green.png";

export function GmetLogo({size} : {size?: number}) {
    size = size || 50;

    return (
        <>
            <img className="App-logo" src={logo} alt="Logo" height={size} />
        </>
    );
}

export default GmetLogo;