import React from 'react';
import * as Yup from "yup";
import {Formik, FormikHelpers} from "formik";
import {Form} from "react-bootstrap";
import {
    EmailControl, HiddenControl,
    SelectControl,
    SubmitButton,
    TextareaControl,
    TextControl
} from "../elements/form/MyForm";
import {postContactForm} from "../../api";
import {toast} from "react-toastify";
import {APP_CLIENT_CONTEXT, APP_REVISION} from "../../constants";

function ContactForm({onSuccess} : {onSuccess?: (values: any) => void}) {

    const initialValues = {
        context: APP_CLIENT_CONTEXT + ":" + APP_REVISION,
        sender_name: process.env.NODE_ENV === 'development' ? "Some Name" : "",
        sender_email: process.env.NODE_ENV === 'development' ? "name@example.org" : "",
        study_type: "lab_experiment",
        service_type: "htp_untargeted",
        text: "",
        sample_count: "small",
        timeframe: "asap",
        role: "faculty",
        affiliation: "DFCI",
        //do_confirm: 1,
    };

    const validationSchema = Yup.object().shape({
        sender_name: Yup.string()
            .min(1)
            .required("This field is required"),
        sender_email: Yup.string()
            .email("This is not a valid email address")
            .required("This field is required"),
        study_type: Yup.string(),
        service_type: Yup.string(),
        text: Yup.string()
            .min(3)
            .required("This field is required"),
        sample_count: Yup.string(),
        timeframe: Yup.string(),
        role: Yup.string(),
        affiliation: Yup.string(),
        //do_confirm: Yup.boolean()
    });

    const handleSubmit = (values: any, formik: FormikHelpers<any>) => {
        // console.log("ContactForm:SUBMIT", values)
        formik.setSubmitting(true)
        postContactForm(values)
            .then((data) => {
                formik.setSubmitting(false)
                formik.resetForm()
                toast.success("Your message has been sent")
                if (typeof onSuccess == "function") {
                    onSuccess(data)
                }
            })
            .catch((e: Error) => {
                formik.setSubmitting(false)
                toast.error("Your message could not be sent. Please try again later.")
            })
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
            {(formik) => (
                <Form onSubmit={formik.handleSubmit} className="mx-auto">
                    <HiddenControl
                        name="context"
                    />
                    <TextControl
                        name="sender_name"
                        label="Name"
                    />
                    <EmailControl
                        name="sender_email"
                        label="Email"
                        placeholder="name@example.com"
                    />

                    <SelectControl
                        name="study_type"
                        label="What type of study are you planning?"
                        options={[
                            {"value": "lab_experiment", "label": "Laboratory experiment" },
                            {"value": "epidemiological_study", "label": "Epidemiological study" },
                            {"value": "other", "label": "Other" },
                        ]}
                    />

                    <SelectControl
                        name="service_type"
                        label='What type of analysis are you planning? (see "Services" for details)'
                        options={[
                            {"value": "htp_untargeted", "label": "High-throughput untargeted metabolome profiling" },
                            {"value": "multi_omics", "label": "Multi-omics network integration" },
                            {"value": "targeted_lcms", "label": "Targeted LC/MS analysis" },
                            {"value": "labeling", "label": "Isotopic fate-mapping" },
                        ]}
                    />

                    <TextareaControl
                        name="text"
                        label="What would you like to discover?"
                        placeholder="Describe your study. What are the primary scientific or technical questions? What metabolites or metabolic pathways are most relevant to your biological question?"
                        rows={6}
                    />


                    <SelectControl
                        name="sample_count"
                        label="What is the approximate size of your study?"
                        options={[
                            {"value": "small_targeted", "label": "Small targeted (<80 samples)" },
                            {"value": "small", "label": "Small high-throughput untargeted (80-160 samples)" },
                            {"value": "medium", "label": "Medium (200-500 samples)" },
                            {"value": "large", "label": "Large (>500 samples)" },
                        ]}
                    />

                    <SelectControl
                        name="timeframe"
                        label="When would you like to measure your samples?"
                        options={[
                            {"value": "asap", "label": "As soon as possible" },
                            {"value": "Summer 2022", "label": "Summer 2022" },
                            {"value": "Fall 2022", "label": "Fall 2022" },
                            {"value": "tbd", "label": "TBD" },
                        ]}
                    />

                    <SelectControl
                        name="role"
                        label="What is your role?"
                        options={[
                            {"value": "faculty", "label": "Faculty" },
                            {"value": "staff", "label": "Scientist" },
                            {"value": "postdoc", "label": "PostDoc" },
                            {"value": "student", "label": "PhD Student" },
                            {"value": "other", "label": "Other" },

                        ]}
                    />

                    <SelectControl
                        name="affiliation"
                        label="What is your affiliation?"
                        options={[
                            {"value": "DFCI", "label": "Dana-Farber Cancer Institute" },
                            {"value": "CHILDREN", "label": "Boston Children's Hospital" },
                            {"value": "EXTERNAL", "label": "External" },
                        ]}
                    />

                    {/*<CheckboxControl
                        name="do_confirm"
                        label="Send a confirmation message to my email address"
                    />*/}

                    <div className="text-end">
                        <SubmitButton block={true} size="lg" />
                    </div>
                </Form>)}
        </Formik>
    );
}

export default ContactForm;
