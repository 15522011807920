import axios from "axios";
import {API_BASE_URL} from "./constants";

///////////////////////////////////////////////////////////////
// AXIOS CLIENT CONFIG
// @link https://axios-http.com/docs/req_config
///////////////////////////////////////////////////////////////
const apiHttp = axios.create({
    baseURL: API_BASE_URL,
    // // `withCredentials` indicates whether or not cross-site Access-Control requests
    // // should be made using credentials
    // withCredentials: false,
    // // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
    // xsrfCookieName: 'csrftoken',
    // // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
    // xsrfHeaderName: 'X-CSRFToken',
})

// axios.defaults.baseURL = API_URL;
// // `withCredentials` indicates whether or not cross-site Access-Control requests
// // should be made using credentials
// axios.defaults.withCredentials = false;
// // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
// axios.defaults.xsrfCookieName = 'csrftoken';
// // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'

// // Add a request interceptor
// apiHttp.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     //console.log("AXIOS:REQ:BEFORE", config)
//     return config;
//   }, function (error) {
//     // Do something with request error
//     //console.log("AXIOS:REQ:ERROR", error)
//     return Promise.reject(error);
//   });
//
// // Add a response interceptor
// apiHttp.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     //console.log("AXIOS:RES:OK", response)
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.log("AXIOS:RES:ERROR", error)
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       //console.log(error.response.data);
//       //console.log(error.response.status);
//       //console.log(error.response.headers);
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//       // http.ClientRequest in node.js
//       //console.log(error.request);
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       //console.log('Error', error.message);
//     }
//     return Promise.reject(error);
//   });

export default apiHttp;