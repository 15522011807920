import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";

import InstrumentImage1 from "../../../assets/img/MS_AgilentFunnel_Q-TOF_LCMS.webp"
import InstrumentImage2 from "../../../assets/img/MS_ThermoFischer_Orbitrap.jpg"
import InstrumentImage3 from "../../../assets/img/MS_SCIEX_Triple_Quad.jpg"

function InstrumentsSection() {
    return (
        <Container>
            <h2 className="text-center pb-5">Instrument overview</h2>
            <Row>
                <Col md={4}>
                    <Card className="text-center border-1 shadow-1 h-100">
                        <img src={InstrumentImage1} className="card-img-top card-img-400" alt="Agilent 6550 iFunnel"/>
                        <Card.Body>
                            <Card.Title as="h4">
                                Agilent 6550 iFunnel™ <br/>
                                Q-TOF FIA-MS
                            </Card.Title>
                            <Card.Text>
                                Flow injection analysis for high throughput metabolome profiling of complex
                                samples (1 min/sample).
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center border-1 shadow-1 h-100">
                        <img src={InstrumentImage2}
                             className="card-img-top card-img-400"
                             height={400}
                             alt="Thermo-Fischer Q Exactive HF™ Orbitrap LC-MS/MS"/>
                        <Card.Body>
                            <Card.Title as="h4">
                                Thermo-Fischer Q Exactive HF™<br />
                                Orbitrap LC-MS/MS
                            </Card.Title>
                            <Card.Text>
                                For LC/MS(/MS) applications requiring the highest resolving power and sensitivity
                                such as metabolic tracer studies.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-center border-1 shadow-1 h-100">
                        <img src={InstrumentImage3} className="card-img-top card-img-400" alt="SCIEX Triple Quad™ LC-MS/MS"/>
                        <Card.Body>
                            <Card.Title as="h4">SCIEX Triple Quadrupole™ <br />LC-MS/MS</Card.Title>
                            <Card.Text>
                                For robust quantitation of known compounds.
                                <br />&nbsp;
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default InstrumentsSection;
