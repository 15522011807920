import React from 'react';
import LandingPage from "./components/pages/LandingPage";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import './App.css';

function App() {

    const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KL4RMT');`

  return (
      <HelmetProvider>
          <Router>
              <Helmet>
                  <meta charSet="utf-8"/>
                  <meta name="color-scheme" content="dark light"/>
                  <title>Dana-Farber Metabolomics Core</title>
                  {process.env.NODE_ENV === 'production' && <script type="text/javascript">{gtmScript}</script>}
              </Helmet>
              <div className="App">
                  <Switch>
                      <Route path="/" exact component={LandingPage} />
                  </Switch>
              </div>
          </Router>
      </HelmetProvider>
  );
}

export default App;
