import React from 'react';
import DfciNavbarDesktop from "./DfciNavbarDesktop";

function DfciFrontendLayout(props: React.PropsWithChildren<any>) {
    return (
        <>
            <div className='App-content Dfci-Layout'>
                <div className="App-navbar">
                    <DfciNavbarDesktop/>
                </div>
                <div className="App-main">
                    {props.children}
                </div>
            </div>
        </>
    );
}

export default DfciFrontendLayout;
