import React from 'react';
import './index.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<React.StrictMode>
    <App />
    <ToastContainer hideProgressBar={true} newestOnTop={true} position={"top-center"}/>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
