import React from 'react';
import Logo from "./Logo";
import {Button} from "react-bootstrap";

const DfciNavbarDesktop = () => {

    const sections = [
        {"label": "Get started", "anchorId": "landing-section-banner"},
        {"label": "Benefits", "anchorId": "section-benefits"},
        {"label": "Instruments", "anchorId": "section-instruments"},
        {"label": "Services", "anchorId": "section-services"},
        {"label": "Workflow", "anchorId": "section-workflow"},
    ]

    return (
        <div className='Dfci-Navbar d-flex flex-row justify-content-between active p-2 shadow fixed-top bg-white'>
            <Logo size={45}/>
            <nav className="Navbar-Desktop-nav">
                <div className="d-flex flex-row flex-md-row justify-content-between align-items-center">
                    <div className="p-1">
                        {sections.map((section) => 
                            <Button key={section.anchorId} variant="link" onClick={(e) => {
                                if (!!document) {
                                    //@ts-ignore
                                    document.getElementById(section.anchorId).scrollIntoView()
                                }
                                e.preventDefault()
                            }}>{section.label}</Button>
                        )}
                    </div>
                </div>

            </nav>
        </div>
    );
}

export default DfciNavbarDesktop;
