import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";

function BenefitsSection() {
    return (
        <Container>
            <h2 className="text-center">How can metabolomics benefit your research?</h2>
            <Row>
                <Col md={12}>
                    <div className="px-3 shadow-0">
                        <Card className="text-center border-0 no-bg">
                            <Card.Body>
                                <Card.Text>
                                    Metabolomics has become a significant driver of fundamental biological,
                                    biomedical, and clinical research to identify, quantify, and study the
                                    dynamics of small molecules within biological systems. Ongoing developments
                                    in analytical instrumentation and data processing methods have increased the
                                    throughput, coverage, and reproducibility of metabolomics experiments,
                                    paving the way for discovery-driven lab experiments and population-scale
                                    studies of metabolism.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Card className="text-center border-0 no-bg">
                                    <Card.Body>
                                        <Card.Text as={"div"} className="text-dark py-5">
                                            <h3>The Dana-Farber Metabolomics Core Will Provide:</h3>
                                            <ul className="Benefits-list mt-5">
                                                <li>Experimental Design and Guidance for Sample Prep</li>
                                                <li>Non-targeted methods for discovery</li>
                                                <li>Targeted + Quantitative methods</li>
                                                <li>Scalable, cloud-based computational resources</li>
                                                <li>Rapid-turn around for high-throughput studies</li>
                                                <li>Support for large-scale studies (1000's of samples)</li>
                                            </ul>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default BenefitsSection;
