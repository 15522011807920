import React from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import WorkflowBannerImage from "../../../assets/img/gmetworkflow_cropped.png"

function WorkflowSection() {
    return (
        <Container>
            <h2 className="text-center pb-5">Working with the Core</h2>
            <Row>
                <Col md={12}>
                    <Card className="text-left border-0 shadow-0">
                        <img src={WorkflowBannerImage} className="card-img-top p-5" alt="Workflow"/>

                        <Row className="mt-5">
                            <Col md={4}>
                                <Card className="text-justify border-0 h-100">
                                    <Card.Body>
                                        <Card.Title as="h4" className="mb-4">Planning & Design</Card.Title>
                                        <Card.Text>
                                            The requirements for sample preparation, analytical method, and
                                            data visualization
                                            vary widely depending on the compounds of interest, sample type,
                                            and biological question.
                                        </Card.Text>
                                        <Card.Text>
                                            First-time users are encouraged to schedule a consultation with
                                            one of the Core's metabolomics experts. Experienced users of high-throughput services 
                                            can also use a web app to independently design, register, and track
                                            their experiments.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="text-justify border-0 h-100">
                                    <Card.Body>
                                        <Card.Title as="h4" className="mb-4">Sample Delivery & Logistics</Card.Title>
                                        <Card.Text>
                                            To provide an efficient, hassle-free experience the Core uses the
                                            metadata provided during the experiment registration process for 
                                            sample submission and analysis.
                                        </Card.Text>
                                        <Card.Text>
                                            Once an experiment is registered,
                                            a chain-of-custody is established that makes the next actions
                                            required predictable and transparent.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={4}>
                                <Card className="text-justify border-0 h-100">
                                    <Card.Body>
                                        <Card.Title as="h4" className="mb-4">Data delivery & analysis</Card.Title>
                                        <Card.Text>
                                            Depending on the type of experiment, the results can be made
                                            available either
                                            as downloadable, raw files, pre-processed data tables, or as
                                            pre-configured
                                            computational notebooks and dashboards hosted by General Metabolics.
                                        </Card.Text>
                                        <Card.Text>
                                            The Core will be continuously adding new
                                            algorithms and visualization tools, and welcome suggestions for
                                            open-source tools
                                            users would like us to include.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default WorkflowSection;
