import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import "./LandingPage.scss"
import ContactForm from "./Contact.form";
import DfciFrontendLayout from "../elements/layout/DfciFrontendLayout";
import InstrumentsSection from "./Landing/Instruments.section";
import ServicesSection from "./Landing/Services.section";
import BenefitsSection from "./Landing/Benefits.section";
import WorkflowSection from "./Landing/Workflow.section";
import GmetLogo from "../elements/layout/GmetLogo";

function LandingPage() {
    const [showForm, setShowForm] = React.useState(false)

    const toggleForm = () => {
        setShowForm(!showForm)
    }

    React.useEffect(() => {
        const elContactForm = window.document.getElementById("landing-contactform");
        if (elContactForm && showForm) {
            console.log("SHOW FORM")
            //elContactForm.scrollIntoView(true);
            const rect = elContactForm.getBoundingClientRect()
            window.scroll({top: rect.top})
        }

    }, [showForm])

    return (
        <DfciFrontendLayout>
            <div id="landing">
                <section id="landing-section-banner" className="landing-section">
                    <Container>
                        <div className="col-md-12 text-center">
                            <h1 className="text-light py-3">
                                Welcome to the Dana-Farber Cancer Institute Metabolomics Core</h1>
                            <Row className="mb-3">
                                <Col><p className="h3 text-white">
                                    Dana-Farber is building an innovative metabolomics facility with
                                    state-of-the-art analytical capabilities and technical expertise to support research
                                    for
                                    the Longwood Medical
                                    area and beyond. We support both academic and biopharma research.
                                </p></Col>
                            </Row>
                            <Row className="mb-3">
                                <Col><p className="h3 text-white">
                                    We offer high-throughput metabolomics screening combined with
                                    downstream validation experiments as well targeted and untargeted
                                    LC/MS(/MS) analyses with a broad metabolite coverage.
                                    All our services are customized to your needs.
                                </p></Col>
                            </Row></div>

                        <div className="text-center">
                            <h2 className="text-light py-3">Supported by Massachusetts Life Sciences Center</h2>
                            <Button size="lg" variant="outline-light"
                                    href="https://www.dana-farber.org/newsroom/news-releases/2021/massachusetts-life-sciences-center-funding-to-support-a-metabolomics-facility-at-dana-farber/"
                                    target={"_blank"}
                            >
                                See press release
                            </Button>
                        </div>

                        <div className="text-center">
                            <h2 className="text-light py-3">Pre-Plan Your Experiments Now</h2>
                            <Button size="lg" variant="outline-light" onClick={toggleForm}>Get started</Button>
                        </div>

                    </Container>
                </section>


                {showForm && <section id="landing-section-contactform" className="landing-section">
                    <Container>

                        <Row className="justify-content-center">
                            <Col md={8}>
                                <div id="landing-contactform">
                                    <h2>Send inquiry</h2>
                                    <ContactForm onSuccess={() => setShowForm(false)}/>
                                    <hr />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>}

                <section className="landing-section landing-section-alt" id="section-benefits">
                    <BenefitsSection/>
                </section>

                <section className="landing-section" id="section-instruments">
                    <InstrumentsSection/>
                </section>

                <section className="landing-section landing-section-alt" id="section-services">
                    <ServicesSection/>
                </section>

                {/*<section className="landing-section">
                    <PricingSection/>
                </section>*/}

                <section className="landing-section" id="section-workflow">
                    <WorkflowSection/>
                </section>

                <footer id="landing-footer" className="bg-light p-5 mt-5 text-dark border-top text-center">
                    <p>
                        <span className="h4">Developed and operated in collaboration with</span>
                    </p>
                    <p>
                        <GmetLogo/>
                    </p>
                    <p>
                        <a className="no-underline"
                           href="https://www.generalmetabolics.com"
                           target="_blank"
                           rel="noreferrer">General Metabolics, LLC.</a>
                    </p>
                    <p>
                        <span>&copy; 2022</span>
                    </p>
                </footer>
            </div>
        </DfciFrontendLayout>
    );
}

export default LandingPage;
